<template>
  <div>
    <c-table
      ref="table"
      title="정비요청 목록"
      :columns="gridPlan.columns"
      :data="gridPlan.data"
      :usePaging="false"
      :isExcelDown="false"
      :filtering="false"
      :columnSetting="false"
      @linkClick="linkClick2"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable && imprTabInfo.key && result.checkStatusCd !== 'MCSC000015'" label="정비요청" icon="add" @btnClicked="openPop" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['woWorkReqStepName']">
          <q-chip
            :color="getColors(props.row.woWorkReqStepCd)"
            text-color="white">
            {{props.row.woWorkReqStepName}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-table
      ref="table"
      :title="imprTabInfo.gridItem.title"
      :columns="gridItemColumns"
      :gridHeight="gridHeight"
      :data="gridItemData"
      :merge="imprTabInfo.gridItem.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="!imprTabInfo.disabled"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'customCol' && props.row['sopImprovementIds'] && props.row['ibmStepNames']">
          <q-chip
            v-for="(item, index) in $comm.ibmTagItems(props.row)"
            :key="index"
            :color="item.color"
            :clickable="true"
            text-color="white"
            style="margin-bottom:4px !important;"
            @click="linkClick(props.row, item.ibmClassCd, item.sopImprovementId, 'item')">
            {{item.title}}
            <c-assess-tooltip 
              :ibmTooltip="item.ibmTooltip"
              :ibmClassCd="item.ibmClassCd"
            />
          </q-chip>
        </template>
        <template v-else-if="col.name === 'gaugeWarn'">
          <font style="font-size:1.0em;font-weight:700;" color="#0300c1">
            {{ props.row.gaugeWarnLcl}}
          </font>
          <font style="font-size:1.0em;font-weight:700;">
            ~
          </font>
          <font style="font-size:1.0em;font-weight:700;" color="#C10015">
            {{ props.row.gaugeWarnUcl}}
          </font>
        </template>
        <template v-else-if="col.name === 'gaugeExec'">
          <font style="font-size:1.0em;font-weight:700;" color="#0300c1">
            {{ props.row.gaugeExecLcl}}
          </font>
          <font style="font-size:1.0em;font-weight:700;">
            ~
          </font>
          <font style="font-size:1.0em;font-weight:700;" color="#C10015">
            {{ props.row.gaugeExecUcl}}
          </font>
        </template>
      </template>
    </c-table>
    <c-table
      ref="gridImpr"
      title="항목외 개선 목록"
      :columns="gridImpr.columns"
      :data="gridImpr.data"
      :gridHeight="gridImpr.height"
      :usePaging="false"
      :filtering="false"
      :columnSetting="false"
      @linkClick="linkClick3"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable && imprTabInfo.key && result.checkStatusCd !== 'MCSC000015'" label="개선요청" :showLoading="false"  icon="add" @btnClicked="addImpr2" />
          <c-btn v-if="editable && imprTabInfo.key && result.checkStatusCd !== 'MCSC000015'" label="즉시조치" :showLoading="false"  icon="add" @btnClicked="addiimImpr2" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-impr',
  props: {
    result: {
      type: Object,
      default: () => ({
        minEquipmentCheckId: '',
        equipmentName: '',
        checkScheduleDate: '',
        checkDate: '',
      }),
    },
    imprTabInfo: {
      type: Object,
      default: () => ({
        key: '', // 
        ibmTaskTypeCd: '',
        requestContents: '',
        disabled: false,
        isOld: false,
        gridItem: {
          listUrl: '',
          param: null,
          title: '항목 별 개선사항 목록',
          data: [],
          merge: [],
          columns: [],
          research: {
            impr: '',
            item: '',
          },
        },
        gridOther: {
          title: '항목외 개선 목록',
        },
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridItem: {
        data: [],
      },
      gridImpr: {
        columns: [
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
        height: '250px'
      },
      gridPlan: {
        columns: [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            fix: true,
            name: 'woWorkReqStepName',
            field: 'woWorkReqStepName',
            label: '진행상태',
            align: 'center',
            type: 'custom',
            style: 'width:100px',
          },
          {
            fix: true,
            name: 'emergencyFlagName',
            field: 'emergencyFlagName',
            label: '구분',
            align: 'center',
            sortable: true,
            style: 'width:50px',
          },
          {
            fix: true,
            name: 'woRequestName',
            field: 'woRequestName',
            label: '작업명',
            align: 'left',
            sortable: true,
            style: 'width:350px',
            type: 'link'
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비Tag',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'funcLocationName',
            field: 'funcLocationName',
            label: '기능위치',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '정비요청 부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'deptNames',
            field: 'deptNames',
            label: '정비오더 발행부서',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'woWorkTypeName',
            field: 'woWorkTypeName',
            label: '작업유형',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'woRequestDt',
            field: 'woRequestDt',
            label: '요청일',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
        ],
        data: [],
      },
      requestImprRow: null,
      editable: true,
      impListUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'imprTabInfo.gridItem.research.impr'() {
      this.getList();
      this.getWorkOrderList();
      this.getImpList();
    }
  },
  computed: {
    gridHeight() {
      return this.contentHeight && (this.contentHeight - 500) > 400 ? String(this.contentHeight - 500) + 'px' : '250px';
    },
    gridItemColumns() {
      return this.$_.concat(this.imprTabInfo.gridItem.columns, [{
        name: 'customCol',
        field: 'customCol',
        label: '개선/진행상태',
        align: 'center',
        style: 'width:200px',
        type: 'custom',
        sortable: false
      }])
    },
    gridItemData() {
      return this.imprTabInfo.gridItem.data && this.imprTabInfo.gridItem.data.length > 0 
        ? this.imprTabInfo.gridItem.data : this.gridItem.data
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.impListUrl = selectConfig.sop.ibm.improve.others.url;
      // code setting
      // list setting
      this.getList();
      this.getWorkOrderList();
      this.getImpList();
    },
    getList() {
      if (this.imprTabInfo.gridItem.listUrl) {
        this.$http.url = this.imprTabInfo.gridItem.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.imprTabInfo.gridItem.param
        this.$http.request((_result) => {
          this.gridItem.data = _result.data;
        },);
      }
    },
    getWorkOrderList() {
      if (this.imprTabInfo.key) {
        this.$http.url = selectConfig.wod.workreq.list.url;
        this.$http.type = 'GET';
        this.$http.param = {
          minEquipmentCheckId: this.imprTabInfo.key
        };
        this.$http.request((_result) => {
          this.gridPlan.data = _result.data;
        },);
      }
    },
    getImpList() {
      if (this.imprTabInfo.key) {
        this.$http.url = this.impListUrl;
        this.$http.param = {ibmTaskTypeCd: 'ITT0000055', relationTableKey: this.result.minEquipmentCheckId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridImpr.data = _result.data;
        },);
      }
    },
    linkClick2(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '정비요청 상세';
      this.popupOptions.param = {
        woRequestId: row ? row.woRequestId : '',
        plantCd: row ? row.plantCd : '',
        equipmentCd: this.result.equipmentCd,
        funcLocationCd: this.result.funcLocationCd,
        costCenterCd: this.result.costCenterCd,
        woRequestDt: this.result.checkDate ? this.result.checkDate : this.result.checkScheduleDate,
        minEquipmentCheckId: this.imprTabInfo.key
      };
      this.popupOptions.target = () => import(`${'@/pages/wod/workOrderRequestDetail.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getWorkOrderList();
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, ibmClassCd, sopImprovementId, flag) {
      if (ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      }
      this.popupOptions.param = {
        sopImprovementId: sopImprovementId,
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      if (flag === 'item') {
        this.requestImprRow = row
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.closeCallback = this.closeImprOtherPopup;
      }
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (this.imprTabInfo.gridItem.research) {
          this.$set(this.imprTabInfo.gridItem.research, 'item', uid())
        }
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          let splitTooltip = this.$_.split(this.requestImprRow.ibmTooltip, '│');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            splitTooltip.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
            this.$set(this.requestImprRow, 'ibmTooltip', splitTooltip.join('│'))
          }
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.getList();
      }
    },
    closeImprOtherPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getImprList();
    },
    /* eslint-disable no-unused-vars */
    addImpr() {
      this.popupOptions.title = '개선 요청';
      this.popupOptions.param = {
        requestContents: this.imprTabInfo.requestContents,
        relationTableKey: this.imprTabInfo.key,
        ibmTaskTypeCd: this.imprTabInfo.ibmTaskTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addImmImpr() {
      this.popupOptions.title = '즉시조치';
      this.popupOptions.param = {
        requestContents: this.imprTabInfo.requestContents,
        relationTableKey: this.imprTabInfo.key,
        ibmTaskTypeCd: this.imprTabInfo.ibmTaskTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImprList();
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WWRSC00001': // 작성중
          returnColor = 'orange';
          break;
        case 'WWRSC00002': // 승인요청중
          returnColor = 'purple';
          break;
        case 'WWRSC00003': // 승인완료
          returnColor = 'green';
          break;
        case 'WWRSC00009': // 접수 반려
          returnColor = 'red';
          break;
        case 'WWRSC00010': // 접수 승인
          returnColor = 'blue';
          break;
      }

      return returnColor;
    },
    addImpr2() {
      let date = this.result.checkDate || this.result.checkScheduleDate
      this.popupOptions.title = '개선 요청';
      this.popupOptions.param = {
        requestContents: '[예방점검] ' + this.result.equipmentName,
        relationTableKey: this.result.minEquipmentCheckId,
        ibmTaskTypeCd: 'ITT0000055',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup2;
    },
    addiimImpr2() {
      let date = this.result.checkDate || this.result.checkScheduleDate
      this.popupOptions.title = '즉시조치';
      this.popupOptions.param = {
        requestContents: '[예방점검] ' + this.result.equipmentName,
        relationTableKey: this.result.minEquipmentCheckId,
        ibmTaskTypeCd: 'ITT0000055',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup2;
    },
    closeAddImprPopup2() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    },
    linkClick3(data, row) {
      this.requestImprRow = row
      this.imprData = data;

      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup2;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.isFull = false;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup2;
      }
    },
  }
};
</script>
